<template>
    <md-card class="md-elevation-24">
        <md-card-header>
            <md-card-header-text>
            <div class="md-title primary">Roeland de Kruijf</div>
            <div class="md-subhead">Freelance software engineer from Utrecht, Netherlands.</div>
            
            </md-card-header-text>
            <md-card-media md-small>
                <img src="@/assets/roelanddekruijf-bg.jpg" alt="Roeland de Kruijf">
            </md-card-media>
        </md-card-header>
        
        <md-card-content>
            Hi! I'm <span class="md-body-2 primary">ROELAND</span> <span class="md-body-2 accent">Dev</span>, a <span class="md-body-2 primary">Microsoft</span> <span class="md-body-2 accent">certified</span> software engineer and architect with <span class="md-body-2 primary">{{ yearsExperience() }} years</span> professional experience in <span class="md-body-2 accent">api</span>, <span class="md-body-2 accent">integration</span>, <span class="md-body-2 accent">full-stack</span> and <span class="md-body-2 accent">web</span> development.
            <span class="md-body-2 primary">{{ yearsExperienceAzure() }} years</span> experience with <span class="md-body-2 accent">Azure</span> and <span class="md-body-2 accent">DevOps</span>. <span class="md-body-2 primary">{{ yearsExperienceAgile() }} years</span> using <span class="md-body-2 accent">Scrum</span> and other <span class="md-body-2 accent">Agile</span> methodologies.
            <p>
                Since {{ yearStarted }} I worked with <span class="md-body-2 accent">.NET</span>, <span class="md-body-2 accent">C#</span> and <span class="md-body-2 primary">Visual Studio</span>, starting with <span class="md-body-2 accent">.NET Framework</span> version 1.1 to 4.8, <span class="md-body-2 accent">.NET Core</span> and now <span class="md-body-2 accent">.NET</span>.
                From {{ yearStartedAzure }} deploying solutions through <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">DevOps</span> with <span class="md-body-2 accent">CI/CD</span> to <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">App Services</span>, <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">Functions</span>, <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">Kubernetes Services</span> and <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">Container Apps</span>.
                Experienced with <span class="md-body-2 primary">Event-driven</span> and <span class="md-body-2 primary">Message-driven</span> approaches with <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">Service Bus</span>, <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent"> Event Hubs</span> and similar brokers.
                Storing data in relational, like <span class="md-body-2 primary">SQL Server</span> and <span class="md-body-2 accent">NoSQL</span>, like <span class="md-body-2 primary">Azure</span> <span class="md-body-2 accent">Cosmos DB</span>. 
                Became passionate about <span class="md-body-2 accent">JavaScript</span> when <span class="md-body-2 primary">jQuery</span> arrived and 
                nowadays enthusiastic using frameworks like <span class="md-body-2 primary">Vue</span>, <span class="md-body-2 primary">React</span> and <span class="md-body-2 primary">Angular</span> with <span class="md-body-2 accent">TypeScript</span>. 
            </p>
            As you can read, <span class="md-body-2 primary">ROELAND</span> <span class="md-body-2 accent">Dev</span> can help your company or organization with a wide range of software and cloud development topics. 
            Please contact me through <span class="md-body-2 linkedin"><a href="https://www.linkedin.com/in/roelanddekruijf">LinkedIn</a></span> for getting in touch.
        </md-card-content>

        <md-card-actions>
            <md-button href="https://www.linkedin.com/in/roelanddekruijf" class="md-icon-button md-raised md-primary">
            <img src="@/assets/linkedin.svg" />
            <!-- <md-icon md-src="../assets/linkedin.svg" /> -->
        </md-button>
        </md-card-actions>
    </md-card>
</template>

<script>
export default {
    name: "RoelandCard",
    data: () => {
        return {
            yearStarted: 2004,
            yearStartedAgile: 2009,
            yearStartedAzure: 2012
        }
    },
    methods: {
        yearsExperience: function() {
            return new Date().getFullYear() - this.yearStarted
        },
        yearsExperienceAgile: function() {
            return new Date().getFullYear() - this.yearStartedAgile
        },
        yearsExperienceAzure: function() {
            return new Date().getFullYear() - this.yearStartedAzure
        }
    }
}
</script>
